@charset "UTF-8";

@import 'variables';
@import 'media-queries';

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,400;0,900;1,200&display=swap');

html {
  box-sizing: border-box;
}
*,
:after,
:before {
  box-sizing: inherit;
}
body,
html {
  color: $textColor;
  font-family: 'Nunito Sans', San Francisco, Roboto, Segoe UI, Helvetica Neue,
    Lucida Grande, sans-serif;
  font-size: 1em;
  margin: 0 0;
  padding: 0 1em;

  height: 100%;
  background-color: $rightColor;
}

footer {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  max-width: 1024px;
  margin: 0 auto;
  padding: 1em;
  text-align: center;
  font-size: 0.7em;
  margin-top: 5em;
}

a {
  color: inherit;
}

main {
  max-width: 1024px;
  margin: 0 auto;
  padding: 1em;
}
