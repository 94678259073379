@import '../scss/layout';

.loginForm {
  @include media(768) {
    width: 50%;
  }

  margin: auto;
  background-color: #eaeaea;
  &-title {
    margin: 0;
    padding: 0;
    font-size: 2em;
    font-weight: bold;
  }
  &-form {
    margin: auto;
    display: flex;
    flex-wrap: wrap;

    color: $rightColor;
    padding: 1em 2em 2em 2em;
  }
  &-section {
    margin: auto;
    width: 100%;
  }
  &-input {
    width: 100%;
    margin-top: 0.3em;

    border: none;
    height: 2em;
  }
  &-label {
    width: 100%;
    font-size: 0.8em;

    display: block;
    margin-top: 1.5em;
  }
  &-error {
    color: #8d0000;
    font-size: 0.7em;
    width: 100%;
    text-align: right;
    display: block;
    min-height: 1.5em;
  }
  &-mainError {
    width: 100%;
    color: #8d0000;
  }
  &-button {
    width: 100%;
    height: 2em;
    border: none;
    background-color: $highlight;
    align-self: flex-end;
    color: white;
    font-weight: bold;
    font-size: 1em;
    margin-top: 1.5em;
  }
}
