@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,400;0,900;1,200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,400;0,900;1,200&display=swap);
html{box-sizing:border-box}*,:after,:before{box-sizing:inherit}body,html{color:#a7b7be;font-family:'Nunito Sans', San Francisco, Roboto, Segoe UI, Helvetica Neue, Lucida Grande, sans-serif;font-size:1em;margin:0 0;padding:0 1em;height:100%;background-color:#1d1e1f}footer{flex-shrink:0;max-width:1024px;margin:0 auto;padding:1em;text-align:center;font-size:0.7em;margin-top:5em}a{color:inherit}main{max-width:1024px;margin:0 auto;padding:1em}


.nav-content{max-width:1024px;margin:0 auto;padding:0 20px;display:flex;justify-content:space-between}.nav-content a{display:block;font-size:14px;line-height:44px;text-decoration:none;transition:all 0.3s;color:#ffffff}.nav-content a:hover,.nav-icon:hover,.search-icon:hover{opacity:0.7}.nav-links{width:80%;display:flex;flex-direction:row;justify-content:space-between}.nav-icon{display:none;grid-gap:5px;grid-template-columns:auto;padding:17px 0;height:10px;cursor:pointer;transition:all 0.3s}.bar{height:1px;width:18px;background:white;transition:0.5s}.show .one{transform:rotate(45deg) translateY(5.5px)}.show .two{transform:rotate(-45deg) translateY(-5.5px)}@media (max-width: 768px){.nav-links{position:fixed;top:44px;right:0;height:0;width:100%;background:#333333;flex-direction:column;justify-content:flex-start;transition:all 2s cubic-bezier(0.19, 1, 0.22, 1)}.show .nav-links{height:100%}.nav-links a{height:0;width:0;opacity:0;overflow:hidden;margin-right:50px;margin-left:50px;transition:opacity 1.5s, height 2s}.show .nav-links a{opacity:1;width:auto;height:auto}.nav-icon{order:1;display:grid}.logo{order:2}.user-icon{order:3}}.react-gravatar{width:40px;height:40px;border-radius:100%;margin:5px;border:2px solid white}

html{box-sizing:border-box}*,:after,:before{box-sizing:inherit}body,html{color:#a7b7be;font-family:'Nunito Sans', San Francisco, Roboto, Segoe UI, Helvetica Neue, Lucida Grande, sans-serif;font-size:1em;margin:0 0;padding:0 1em;height:100%;background-color:#1d1e1f}footer{flex-shrink:0;max-width:1024px;margin:0 auto;padding:1em;text-align:center;font-size:0.7em;margin-top:5em}a{color:inherit}main{max-width:1024px;margin:0 auto;padding:1em}.loginForm{margin:auto;background-color:#eaeaea}@media (min-width: 768px), print{.loginForm{width:50%}}.loginForm-title{margin:0;padding:0;font-size:2em;font-weight:bold}.loginForm-form{margin:auto;display:flex;flex-wrap:wrap;color:#1d1e1f;padding:1em 2em 2em 2em}.loginForm-section{margin:auto;width:100%}.loginForm-input{width:100%;margin-top:0.3em;border:none;height:2em}.loginForm-label{width:100%;font-size:0.8em;display:block;margin-top:1.5em}.loginForm-error{color:#8d0000;font-size:0.7em;width:100%;text-align:right;display:block;min-height:1.5em}.loginForm-mainError{width:100%;color:#8d0000}.loginForm-button{width:100%;height:2em;border:none;background-color:#13ad58;align-self:flex-end;color:white;font-weight:bold;font-size:1em;margin-top:1.5em}

.plant{background-color:black}

