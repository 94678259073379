/**
 * Breakpoints
 * ========================================================================= */

$breakpoints: (
  360: 360px,
  480: 480px,
  768: 768px,
  1024: 1024px,
  1200: 1200px,
) !default;

/**
* Colors
* ========================================================================= */

$highlight: #13ad58;
$leftColor: white;
$rightColor: #1d1e1f;
$textColor: #a7b7be;

/**
* Container
* ========================================================================= */
$leftSize: 1;
$rightSize: 1.5;
